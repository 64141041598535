<template>
  <v-footer color="#033436">
    <v-container>
      <v-row >

        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <v-col>
            <img class="mt-5" :src="footerLogo" height="100"/>
          </v-col>
          <v-col>
            <p class="header-footer-text">{{ headerFooter[2] }}</p>
          </v-col>
          <v-col>
            <p class="text-footer-darley-h2 text-white"><v-icon class="footer-icon-color mr-4" icon="mdi:mdi-phone"></v-icon>{{ tel }}</p>
          </v-col>
          <v-col>
            <p class="text-footer-darley-h2 text-white"><v-icon class="footer-icon-color mr-4" icon="mdi:mdi-email"></v-icon>{{ email }}</p>
          </v-col>
          <v-col>
            <v-icon class="footer-icon-color" icon="fab fa-line" href="https://lin.ee/b0zN9dF"></v-icon>
            <v-btn variant="text" href="https://lin.ee/b0zN9dF"><p class="text-footer-darley-h2 text-white">{{ line }}</p></v-btn>
          </v-col>
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <v-col>
            <p class="header-footer-text">{{ headerFooter[0] }}</p>
            <!-- <p class="text-white text-uppercase mt-5 text-h4 header-footer-text">{{ headerFooter[0] }}</p> -->
          </v-col>
          <v-col>
            <v-list-item v-for="(item, i) in footerLinks" :key="i" class="text-start">
              <router-link :to=item.link class="not-active-link-footer" exact-active-class="active-link-footer">
                <v-list-item-title @click="scrollToTop"><p class="header-footer-link-text">{{ item.text }}</p></v-list-item-title>
              </router-link>
            </v-list-item>
          </v-col>          
        </v-col>

        <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <v-col>
            <p class="header-footer-text">{{ headerFooter[1] }}</p>
          </v-col>
          <v-col>
            <iframe :height="isMobile ? 200:300" :width="isMobile ? 200:300" loading="lazy" src="https://maps.google.com/maps?q=94%2C%20QXRX%2BM6J%2C%2096%20Ratchawong%20Rd%2C%20Tambon%20Chang%20Moi%2C%20Mueang%20Chiang%20Mai%20District%2C%20Chiang%20Mai%2050300&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="94, QXRX+M6J, 96 Ratchawong Rd, Tambon Chang Moi, Mueang Chiang Mai District, Chiang Mai 50300" aria-label="94, QXRX+M6J, 96 Ratchawong Rd, Tambon Chang Moi, Mueang Chiang Mai District, Chiang Mai 50300"></iframe>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({

    footerLogo: require("@/assets/images/logo_navbar.png"),
    tel:'+66800998881',
    email:'darleyhotel@asiahotel.co.th',
    line:'@darleyhotelcnx',

  }),
  computed: {
    ...mapGetters(['selectedLanguage']),
    isMobile() {
      const display = this.$vuetify.display
      if (display.xs || display.sm) {
        return true
      }
      else{
        return false
      }
    },
    headerFooter() {
      const textHeader = {
        'Thai': ['เมนู', 'ตำแหน่งที่ตั้ง','ข้อมูลการติดต่อ'],
        'English': ['MENU', 'Location','Information'],
        'Chinese': ['菜单', '地点','信息'],
        'Korean': ['메뉴', '위치','연락처 정보'],
        'Japanese': ['メニュー', '位置','連絡先'],
      };
      return textHeader[this.selectedLanguage] || textHeader['English'];
    },
    footerLinks() {
      const linkMap = {
        'Thai': [
          { text: 'หน้าแรก', link: '/' },
          { text: 'ห้องพัก', link: '/rooms' },
          { text: 'ห้องประชุม', link: '/meeting' },
          { text: 'แกลลอรี่', link: '/gallery' },
        ],
        'English': [
          { text: 'Home', link: '/' },
          { text: 'Room', link: '/rooms' },
          { text: 'Meeting Room', link: '/meeting' },
          { text: 'Gallery', link: '/gallery' },
        ],
        'Chinese': [
          { text: '第一页', link: '/' },
          { text: '房间', link: '/rooms' },
          { text: '会议室', link: '/meeting' },
          { text: '画廊', link: '/gallery' },
        ],
        'Korean': [
          { text: '홈페이지', link: '/' },
          { text: '방', link: '/rooms' },
          { text: '회의실', link: '/meeting' },
          { text: '갱도', link: '/gallery' },
        ],
        'Japanese': [
          { text: 'ホームページ', link: '/' },
          { text: '部屋', link: '/rooms' },
          { text: '会議室', link: '/meeting' },
          { text: 'ギャラリー', link: '/gallery' },
        ],
      };
      return linkMap[this.selectedLanguage] || linkMap['English'];
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
