<template>
  <v-app>
    <NavbarHome />
    <v-main>
      <v-carousel show-arrows="hover" :height="isMobile ? 300:500" cycle>
        <v-carousel-item v-for="(item, i) in carouselItems" :key="i" :src="item.src" cover></v-carousel-item>
      </v-carousel>
      <v-container class="container-home-padding">

        <v-row class="text-center">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <p class="text-darley-h2  text-uppercase font-weight-bold font-color-darley mt-10">{{headerParagraphOne}}</p>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <p class="text-darley-helvethaica-h6 mt-5">{{titleParagraphOne}}</p>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <p class="text-darley-h2 font-weight-bold font-color-darley">{{headerParagraphTwo}}</p>
          </v-col>
        </v-row>
  
        <v-row class="">
          <v-col v-for="(item, i) in imageItems" :key="i" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="text-center home-paragraph">
            <v-img :src=item.src height="250" gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.5)" cover>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-col cols="12">
                  <p class="text-image-darley-h6 text-white font-weight-bold">{{item.text}}</p>
                </v-col>
              </v-row>
            </v-img>     
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <p class="text-darley-h2 font-weight-bold font-color-darley">{{headerParagraphThree}}</p>
          </v-col>
        </v-row>

        <v-row class="home-last-section">
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-row>
              <v-col>
                <v-img class="home-image-last-section" :src=titleImage height="500"></v-img>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="mt-5" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" :class="isMobile ? 'home-last-section-col':'home-last-section-col'">
            <p class="text-darley-helvethaica-h6-last-section" v-for="(item, i) in titleParagraphThree" :key="i"><v-icon class="mr-2" icon="fas fa-check" size="15" color="#eb6e7b"></v-icon>{{ item }}</p>
          </v-col>
        </v-row>
      </v-container>

    </v-main>
    <FooterHome />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import { defineComponent } from "vue";

import NavbarHome from "../components/home/NavbarHome.vue";
import FooterHome from "../components/home/FooterHome.vue";

export default defineComponent({
  name: "HomeView",

  components: {
    NavbarHome,
    FooterHome,
  },
  data: () => ({
    carouselItems: [
          {src: require("@/assets/images/DLH-56.jpg"),},
          {src: require("@/assets/images/DLH-58-scaled-e1688830196100.jpg"),},
          {src:require("@/assets/images/DLH-59.jpeg"),},
        ],
    
    titleImage: require("@/assets/images/246_01-Converted-01.png"),text: 'Superior Room',
  }),
  computed: {
    ...mapGetters(['selectedLanguage']),
    isMobile() {
      const display = this.$vuetify.display
      if (display.xs || display.sm) {
        return true
      }
      else{
        return false
      }
    },
    headerParagraphOne() {
      const headerParagraphOne = {
        'Thai': 'โรงแรมดาร์เลย์เชียงใหม่',
        'English': 'Darley hotel Chiangmai',
        'Chinese': '清迈达利酒店',
        'Korean': '달리 호텔 치앙마이',
        'Japanese': 'ダーリー ホテル チェンマイ',
      };
      return headerParagraphOne[this.selectedLanguage] || headerParagraphOne['English'];
    },
    titleParagraphOne() {
      const titleParagraphOne = {
        'Thai': 'ดาร์เลย์ โฮเทล เชียงใหม่ ตั้งอยู่ในย่านช้างม่อย ทำเลที่โดดเด่นกลางเมืองเชียงใหม่'+
                'ให้คุณได้สัมผัสชีวิตการท่องเที่ยวในแบบคนเมืองเพลิดเพลินไปกับแหล่ง'+
                'shopping และสถานที่ท่องเที่ยวโดยรอบโรงแรม ดาร์เลย์ โฮเทล เชียงใหม่ โรงแรมที่'+
                'ออกแบบตกแต่งในสไตล์ลอฟ ผสานความอบอุ่นแบบเรียบง่ายที่ได้จากกลิ่นไอของ'+
                'ไม้โมเดิร์น สร้างความผ่อนคลายในขณะที่พักผ่อน แต่ยังคงความสวยงามของ'+
                'อารยธรรมท้องถิ่นไว้ด้วยของตกแต่งที่อยู่ภายในห้องเราออกแบบมาเพื่อให้คุณได้'+
                'สัมผัสกับความเรียบง่าย ผ่อนคลาย สไตล์คนเมือง',
        'English':  "Darley Hotel Chiang Mai is located in the Chang Moi area." +
                    " A prominent location in the middle of Chiang Mai allows you" +
                    " to experience the city's travel life, enjoy the shopping and "+
                    " tourist attractions surrounding Darley Hotel Chiang Mai, a hotel"+
                    " designed and decorated in a loft style. Combined with the simple"+
                    " warmth that comes from the scent of modern wood. Create relaxation"+
                    " while resting. But still retaining the beauty of local civilization"+
                    " with the decorations inside our rooms designed for you to experience"+
                    " simplicity, relaxation, urban style.",
        'Chinese':  "清迈达利酒店 位于 Chang Moi 地区。位于清迈市中心的显着位置，"+
                  "让您体验这座城市的旅行生活，享受清迈达利酒店周边的购物和旅游景点，"+
                  "这是一家以阁楼风格设计和装饰的酒店。结合来自现代木材气味的简单温暖。"+
                  "休息时创造放松。但仍保留了当地文明之美，房间内的装饰专为您体验简约、轻松、都市风格",
        'Korean': '달리 호텔 치앙마이(Darley Hotel Chiang Mai)는 창모이(Chang Moi) 지역에 위치해 있습니다. 치앙마이 중심부의 눈에 띄는 위치 덕분에 로프트 스타일로 디자인되고 장식된 호텔인 달리 호텔 치앙마이(Darley Hotel Chiang Mai) 주변에서는 도시의 여행 생활을 경험하고 쇼핑과 관광 명소를 즐길 수 있습니다. 모던한 나무 향이 전해주는 소박한 따뜻함과 어우러져요. 휴식하는 동안 휴식을 취하십시오. 하지만 단순함, 휴식, 도시 스타일을 경험할 수 있도록 설계된 객실 내부 장식으로 지역 문명의 아름다움을 여전히 유지하고 있습니다.',
        'Japanese': 'ダーリー ホテル チェンマイはチャン モイ地区にあります。チェンマイ中心部の目立つ場所にあるダーリー ホテル チェンマイは、ロフト スタイルでデザインおよび装飾されたホテルで、チェンマイの旅行生活を体験したり、周辺のショッピングや観光スポットを楽しんだりすることができます。モダンな木の香りが醸し出す素朴な温もりと組み合わせます。休息中にリラクゼーションを生み出します。地元の文明の美しさもそのままに、客室内の装飾はシンプルさ、リラクゼーション、都会的なスタイルを体験していただけるようデザインされています。',
      };
      return titleParagraphOne[this.selectedLanguage] || titleParagraphOne['English'];
    },
    headerParagraphThree() {
      const headerParagraphThree = {
        'Thai': 'รอบโรงแรม',
        'English': 'Around Darley Hotel',
        'Chinese': '酒店周边',
        'Korean': '호텔 주변',
        'Japanese': 'ホテル周辺',
      };
      return headerParagraphThree[this.selectedLanguage] || headerParagraphThree['English'];
    },
    titleParagraphThree() {
      const titleParagraphThree = {
        'Thai': [ 
                  '4 นาที จากตลาดวโรรส ตลาดต้นลำไย ตลาดดอกไม้',
                  '5 นาที จากสถานกงสุลใหญ่สหรัฐอเมริกา',
                  '10 นาที จากประตูท่าแพ ย่านวัดเกต',
                  '13 นาที จากตลาดไนท์บาซาร์ เชียงใหม่',
                  '15 นาที จากสนามบินเชียงใหม่',
                ],
        'English': [ 
                      '4 minutes from Warorot Market',
                      '5 minutes from the United States Consulate General.',
                      '10 minutes from Tha Phae Gate, Wat Ket area.',
                      '13 minutes from Chiang Mai Night Bazaar.',
                      '15 minutes from Chiang Mai Airport',
                    ],
        'Chinese': [ 
                    '距瓦洛洛市场 4 分钟',
                    '距美国总领事馆 5 分钟路程',
                    '距 Wat Ket 区 Tha Phae 门 10 分钟路程。',
                    '距清迈夜市 13 分钟路程。',
                    '距清迈机场 15 分钟',
                  ],
        'Korean': [ 
                    '와로롯 시장, 톤롱안 시장, 꽃시장에서 4분 거리',
                    '미국 총영사관에서 5분 거리.',
                    '왓켓(Wat Ket) 지역의 타패 게이트(Tha Phae Gate)에서 10분 거리.',
                    '치앙마이 나이트바자차로 13분',
                    '치앙마이 공항에서 15분',
                  ],
        'Japanese': [ 
                    'ワロロット市場から 4分',
                    '米国総領事館から5分。',
                    'ターペー門、ワットケットエリアから10分',
                    'チェンマイ ナイト バザールからは 13 分',
                    'チェンマイ空港から15分',
                  ],
      };
      return titleParagraphThree[this.selectedLanguage] || titleParagraphThree['English'];
    },
    headerParagraphTwo() {
      const headerParagraphTwo = {
        'Thai': 'Rooms',
        'English': 'Rooms',
        'Chinese': '房間',
        'Korean': '객실',
        'Japanese': '部屋',
      };
      return headerParagraphTwo[this.selectedLanguage] || headerParagraphTwo['English'];
    },
    imageItems() {
      const imageItems = {
        'Thai': [
          {src: require("@/assets/images/DLH-38-scaled.jpg"),text: 'Superior Room'},
          {src: require("@/assets/images/DLH-3-1-scaled.jpg"),text: 'Premier Room'},
          {src:require("@/assets/images/DLH-31-1-scaled.jpg"),text: 'Deluxe Double Room'},
          {src:require("@/assets/images/DLH-21-1-scaled.jpg"),text: 'Deluxe Double Single Room'},
        ],
        'English': [
          {src: require("@/assets/images/DLH-38-scaled.jpg"),text: 'Superior Room'},
          {src: require("@/assets/images/DLH-3-1-scaled.jpg"),text: 'Premier Room'},
          {src:require("@/assets/images/DLH-31-1-scaled.jpg"),text: 'Deluxe Double Room'},
          {src:require("@/assets/images/DLH-21-1-scaled.jpg"),text: 'Deluxe Double Single Room'},
        ],
        'Chinese': [
          {src: require("@/assets/images/DLH-38-scaled.jpg"),text: '高级客房'},
          {src: require("@/assets/images/DLH-3-1-scaled.jpg"),text: '豪华房'},
          {src:require("@/assets/images/DLH-31-1-scaled.jpg"),text: '豪华双人间'},
          {src:require("@/assets/images/DLH-21-1-scaled.jpg"),text: '豪华双人单人间'},
        ],
        'Korean': [
          {src: require("@/assets/images/DLH-38-scaled.jpg"),text: '슈페리어룸'},
          {src: require("@/assets/images/DLH-3-1-scaled.jpg"),text: '프리미어룸'},
          {src:require("@/assets/images/DLH-31-1-scaled.jpg"),text: '디럭스 더블룸'},
          {src:require("@/assets/images/DLH-21-1-scaled.jpg"),text: '디럭스 더블 싱글 룸'},
        ],
        'Japanese': [
          {src: require("@/assets/images/DLH-38-scaled.jpg"),text: 'スーペリアルーム'},
          {src: require("@/assets/images/DLH-3-1-scaled.jpg"),text: 'プレミアルーム'},
          {src:require("@/assets/images/DLH-31-1-scaled.jpg"),text: 'デラックス ダブルルーム'},
          {src:require("@/assets/images/DLH-21-1-scaled.jpg"),text: 'デラックス ダブル シングル ルーム'},
        ],
      };
      return imageItems[this.selectedLanguage] || imageItems['English'];
    },
  },
});
</script>
