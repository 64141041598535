import { createStore } from 'vuex'

export default createStore({
  state: {
    selectedLanguage: localStorage.getItem('selectedLanguage') || 'Thai',
  },
  getters: {
    selectedLanguage: (state) => state.selectedLanguage,
  },
  mutations: {
    SET_LANGUAGE(state, language) {
      state.selectedLanguage = language;
      localStorage.setItem('selectedLanguage', language); // Persist to local storage
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
    },
  },
  modules: {
  }
})
