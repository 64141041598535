import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/styles/fonts.css';
import './assets/styles/main.css';
import './assets/styles/main_mobile.css';
import './assets/styles/main_tablet.css';
import './assets/styles/main_dasktop.css';
import './assets/fonts/DB-Sharp-X-v3.2.ttf';
import './assets/fonts/DB-Sharp-X-Bd-v3.2.ttf';
import './assets/fonts/DB-Helvethaica-X-v3.2.ttf';
import './assets/fonts/DB-Manow-Test-X.ttf';
import { loadFonts } from './plugins/webfontloader'

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
